import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { FC } from "react";
import { ServiceScenarioDetailPageQuery } from "../../../graphql-types";
import Button from "../../components/Button";
import {
  MainContainer,
  PageContainer,
  SkewMainContainer,
  TopSkewBgContainer,
} from "../../components/Container";
import Footer from "../../components/Footer";
import Header, { ThemeEnum } from "../../components/Header";
import { HtmlRender } from "../../components/HtmlRender";
import Metadata, { mapMetadata } from "../../components/Metadata";
import NormalBackground from "../../components/NormalBackground";
import { PageTitle } from "../../components/Title";
import { ServiceScenarioIcon } from "../../images/serviceScenario";
import LearnMoreView from "../../Views/LearnMoreView";
import TechnicalSolutionsView from "../../Views/serviceScenarioViews/TechnicalSolutionsView";
import { PageTitleWithIcon } from "../price";

const ServiceScenarioDetailPage: FC<{
  data: ServiceScenarioDetailPageQuery;
}> = ({ data: { contentfulScenario } }) => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...mapMetadata(contentfulScenario.metadata)} />
      <TopSkewBgContainer
        className="sm:h-[680px]"
        contentWrapClassName="h-full"
      >
        <NormalBackground />
      </TopSkewBgContainer>
      <MainContainer>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div>
            <PageTitleWithIcon
              title={t("服务场景")}
              icon={<ServiceScenarioIcon />}
            />
            <PageTitle>{contentfulScenario?.title}</PageTitle>
            <p className="text-gray-500 mt-8 mb-12 sm:mb-10">
              {contentfulScenario?.description?.description}
            </p>
            <Button onClick={() => navigate("/contact")}>
              {t("联系销售")}
            </Button>
          </div>
          <div className="mt-8 w-full sm:w-[640px] h-[280px] sm:h-[440px]">
            <HtmlRender
              html={contentfulScenario?.detailCoverImage?.detailCoverImage!}
            />
          </div>
        </div>
      </MainContainer>
      {contentfulScenario?.detailHtml?.detailHtml! && (
        <SkewMainContainer className="sm:mt-16" fillClassName="bg-white">
          <HtmlRender html={contentfulScenario?.detailHtml?.detailHtml!} />
        </SkewMainContainer>
      )}
      <TechnicalSolutionsView
        lists={contentfulScenario?.technical_solutions ?? []}
      />
      {/*{contentfulScenario?.casestudy?.length > 0 && (*/}
      {/*  <SkewMainContainer fillClassName="light-gray-f6-bg">*/}
      {/*    <SectionTitle className="mb-8">{t("相关案例")}</SectionTitle>*/}
      {/*    <RenderCustomerCases*/}
      {/*      relatedCases={contentfulScenario?.casestudy ?? []}*/}
      {/*    />*/}
      {/*  </SkewMainContainer>*/}
      {/*)}*/}
      <LearnMoreView
        leftUrl={"/contact"}
        leftText={t("填写需求表格")}
        rightText={t("了解价格")}
        rightUrl={"/price"}
      />
      <Footer />
    </PageContainer>
  );
};
export default ServiceScenarioDetailPage;

export const query = graphql`
  query ServiceScenarioDetailPage($slug: String!, $language: String!) {
    ...AllLangauages
    contentfulScenario(slug: { eq: $slug }, node_locale: { eq: $language }) {
      title
      description {
        description
      }
      preview_logo {
        ...ContentfulImage200x200
      }
      detailCoverImage {
        detailCoverImage
      }
      detailHtml {
        detailHtml
      }
      technical_solutions {
        icon {
          ...ContentfulImage200x200
        }
        coverImage {
          ...ContentfulImage200x200
        }
        name
        description {
          description
        }
        technologyType {
          title
          slug
        }
        serviceScenarios {
          title
        }
      }
      casestudy {
        slug
        title
        preview_logo {
          ...ContentfulImage200x200
        }
        customer {
          words
          companyName
          position
          avatar {
            ...ContentfulImage200x200
          }
          name
        }
      }
      metadata {
        ...MetadataInfo
      }
    }
  }
`;
