import * as React from "react";

const NormalBackground = () => {
  return <div className="custom-gray-bg absolute inset-0 w-full h-full" />;
};

export const WhiteBackGround = () => {
  return <div className="bg-white absolute inset-0 w-full h-full" />;
};

export default NormalBackground;
