import { Carousel, Modal } from "antd";
import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo, useMemo, useState } from "react";
import Tilt from "react-tilt";
import { IndexPageQuery } from "../../../graphql-types";
import { LinkButton } from "../../components/Button";
import { ChildrenProps, SkewMainContainer } from "../../components/Container";
import ContentfulImage from "../../components/ContentfulImage";
import { SectionTitle } from "../../components/Title";
import {
  advantageDetail,
  priceAdvantage,
  ServiceAdvantage10000,
  ServiceAdvantage100_blue,
  ServiceAdvantage3X,
  ServiceAdvantage50,
  ServiceAdvantage999,
  ServiceAdvantageThousandEN,
  ServiceAdvantageThousandZH,
  SvgAdvantage01,
  SvgAdvantage02,
  SvgAdvantage03,
  SvgAdvantage04,
  SvgAdvantage05,
  SvgAdvantage06,
} from "../../images/index/index";
import { chunkItems, useLanguage } from "../../utils/utils";

const BigCardItem = (props: ChildrenProps) => {
  return (
    <div
      className={classNames(
        "rounded-lg advantage-small-card-shadow p-8",
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

const SmallCardListOne = () => {
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-12 ">
      <Tilt
        className="Tilt w-full h-64 bg-white rounded-md p-8 advantage-small-card-shadow"
        options={{ max: 15, reverse: true, speed: 1500, perspective: 2000 }}
      >
        <div>
          <div className="flex items-end">
            <ServiceAdvantage50 className="mb-2" />
          </div>
          <p className="mt-1 text-base">{t("自动化标注")}</p>
        </div>
        <div className="Tilt-inner">
          <div className="flex justify-end">
            <SvgAdvantage01 className="float-right scale-90" />
          </div>
        </div>
      </Tilt>
      <Tilt
        className="Tilt w-full h-64 bg-[#B7B8E2] rounded-md p-8 advantage-small-card-shadow"
        options={{ max: 15, reverse: true, speed: 1500, perspective: 2000 }}
      >
        <div>
          <div className="flex items-end">
            <ServiceAdvantage999 className="mb-2" />
          </div>
          <p className="mt-1 text-base text-white">{t("数据交付质量")}</p>
        </div>
        <div className="Tilt-inner">
          <div className="flex justify-end">
            <SvgAdvantage02 className="float-right scale-90" />
          </div>
        </div>
      </Tilt>
      <Tilt
        className="Tilt w-full h-64 bg-[#F0EBFF] rounded-md p-8 advantage-small-card-shadow"
        options={{ max: 15, reverse: true, speed: 1500, perspective: 2000 }}
      >
        <div>
          <div className="flex items-end">
            {isEnUs ? (
              <ServiceAdvantageThousandEN className="mb-2" />
            ) : (
              <ServiceAdvantageThousandZH className="mb-2" />
            )}
          </div>
          <p className="mt-2 text-base">{t("项目打磨")}</p>
        </div>
        <div className="Tilt-inner">
          <div className="flex justify-end">
            <SvgAdvantage03 className="float-right scale-90" />
          </div>
        </div>
      </Tilt>
    </div>
  );
};

const OnlyOneCard = memo(
  (props: { technicalSolutions: TechnicalSolutionsItem[] }) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [currentInfo, setCurrentInfo] = useState<TechnicalSolutionsItem>({});
    const carouselItems = useMemo(
      () => chunkItems(props.technicalSolutions, 8),
      [props.technicalSolutions]
    );
    return (
      <div className="bg-white rounded-md advantage-small-card-shadow mt-10 px-8 pt-8 pb-12">
        <p className="text-4xl advantage-only-one-title text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-400">
          {t("全场景覆盖")}
        </p>
        <p className="mt-2 text-base">
          {t("AI算法场景覆盖：计算机视觉、语音、自然语言处理等")}
        </p>
        <Carousel
          autoplay
          dots={{ className: "index-customer-dot only-one-card-dot" }}
        >
          {carouselItems.map((solutions, index) => {
            return (
              <div key={index}>
                <div className="grid grid-cols-2 gap-4 sm:gap-8 sm:grid-cols-4 mt-8">
                  {solutions.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="flex flex-col items-center cursor-pointer"
                        onClick={() => {
                          setVisible(true);
                          setCurrentInfo(item);
                        }}
                      >
                        <ContentfulImage
                          className="flex-none w-full rounded sm:rounded-lg"
                          image={item?.coverImage!}
                        />
                        <p className="text-black opacity-40 mt-2">
                          {item.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Carousel>
        <TechnicalSolutionInfoModal
          technicalSolutionInfo={currentInfo}
          visible={visible}
          onCancel={() => setVisible(false)}
        />
      </div>
    );
  }
);

const BigCard = () => {
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-10">
      <BigCardItem className="bg-[#282A4B] flex flex-col relative advantage-detail-wrap cursor-pointer ">
        <div className="advantage-small-card-white-text flex items-end">
          <p className="text-4xl">{t("数百个")}</p>
        </div>
        <p className="text-white mt-2 opacity-80">{t("细节功能")}</p>
        <div className="mt-10 overflow-hidden">
          <img className="mb-4" src={advantageDetail} alt="" />
          <img src={advantageDetail} alt="" />
        </div>
        <div className="absolute inset-0 advantage-detail-link rounded-lg justify-center items-center">
          <LinkButton linkUrl="/product">{t("了解更多")}</LinkButton>
        </div>
      </BigCardItem>
      <BigCardItem className="bg-white">
        <h3 className="text-4xl advantage-big-card-blue-title">
          {t("量身定制的解决方案")}
        </h3>
        <p className="mt-2 opacity-80">{t("根据您的需求提供极佳解决方案")}</p>
        <img src={priceAdvantage} alt="" />
        {/*{isEnUs ? <SvgPriceAdvantageEN /> : <SvgPriceAdvantageZH />}*/}
      </BigCardItem>
    </div>
  );
};

const SmallCardTwo = () => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-12 mt-12">
      <Tilt
        className="Tilt w-full h-64 bg-white rounded-md p-8 advantage-small-card-shadow"
        options={{ max: 15, reverse: true, speed: 1500, perspective: 2000 }}
      >
        <div>
          <div className="flex items-end">
            <ServiceAdvantage100_blue className="mb-2" />
          </div>
          <p className="mt-1 text-base">{t("数据安全")}</p>
        </div>
        <div className="Tilt-inner">
          <div className="flex justify-end">
            <SvgAdvantage04 className="float-right scale-90" />
          </div>
        </div>
      </Tilt>
      <Tilt
        className="Tilt w-full h-64 bg-white rounded-md p-8 advantage-small-card-shadow"
        options={{ max: 15, reverse: true, speed: 1500, perspective: 2000 }}
      >
        <div>
          <div className="flex items-end">
            <ServiceAdvantage10000 className="mb-2" />
          </div>
          <p className="mt-1 text-base">{t("交付效率提升")}</p>
        </div>
        <div className="Tilt-inner">
          <div className="flex justify-end">
            <SvgAdvantage05 className="float-right scale-90" />
          </div>
        </div>
      </Tilt>
      <Tilt
        className="Tilt w-full h-64 bg-white rounded-md p-8 advantage-small-card-shadow"
        options={{ max: 15, reverse: true, speed: 1500, perspective: 2000 }}
      >
        <div>
          <div className="flex items-end">
            <ServiceAdvantage3X className="mb-2" />
          </div>
          <p className="mt-1 text-base">{t("更快项目工期")}</p>
        </div>
        <div className="Tilt-inner">
          <div className="flex justify-end">
            <SvgAdvantage06 className="float-right scale-90" />
          </div>
        </div>
      </Tilt>
    </div>
  );
};

type TechnicalSolutionsItem =
  IndexPageQuery["allContentfulSolutions"]["edges"][number];

export const TechnicalSolutionInfoModal = memo(
  ({
    technicalSolutionInfo,
    visible,
    onCancel,
  }: {
    technicalSolutionInfo: TechnicalSolutionsItem;
    visible: boolean;
    onCancel: () => void;
  }) => {
    const { t } = useTranslation();
    if (!visible) {
      return null;
    }
    return (
      <Modal
        visible={visible}
        footer={null}
        width={640}
        onCancel={() => onCancel()}
      >
        <p>{technicalSolutionInfo.technologyType?.title}</p>
        <div className="flex items-center mt-2">
          <ContentfulImage
            className="flex-none w-6 mr-2"
            image={technicalSolutionInfo?.icon!}
          />
          <h3 className="text-2xl">{technicalSolutionInfo.name}</h3>
        </div>
        <div className="rounded-lg flex-none mt-8 mb-6">
          <ContentfulImage
            className="w-full"
            image={technicalSolutionInfo?.coverImage!}
          />
        </div>
        <p>{technicalSolutionInfo.description?.description}</p>
        <p className="mt-8 text-color-666">{t("服务场景")}</p>
        <p className="mt-2">
          {technicalSolutionInfo?.serviceScenarios
            ?.map((s: { title: string }) => s.title)
            .join("、")}
        </p>
      </Modal>
    );
  }
);

export const ServiceAdvantageView = memo(
  (props: { technicalSolutions: TechnicalSolutionsItem[] }) => {
    const { t } = useTranslation();
    return (
      <SkewMainContainer fillClassName="bg-gray-50">
        <SectionTitle id="serviceCharacter" className="text-black mb-12">
          {t("服务优势")}
        </SectionTitle>
        <SmallCardListOne />
        <OnlyOneCard technicalSolutions={props.technicalSolutions} />
        <BigCard />
        <SmallCardTwo />
      </SkewMainContainer>
    );
  }
);
