import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useState } from "react";
import { TechnicalSolutionDetailPageQuery } from "../../../graphql-types";
import { SkewMainContainer } from "../../components/Container";
import ContentfulImage from "../../components/ContentfulImage";
import { SectionTitle } from "../../components/Title";
import { Element } from "../../utils/utils";
import { TechnicalSolutionInfoModal } from "../indexViews/ServiceAdvantageView";

type TechnicalSolutionsItem = Element<
  NonNullable<
    TechnicalSolutionDetailPageQuery["contentfulTechnicalSolutions"]
  >["serviceScenarios"]
>;

const TechnicalSolutionsView = ({
  lists,
}: {
  lists: TechnicalSolutionsItem[];
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [currentInfo, setCurrentInfo] = useState<TechnicalSolutionsItem>({});
  if (lists.length === 0) {
    return null;
  }
  return (
    <SkewMainContainer fillClassName="bg-white">
      <SectionTitle className="mb-8">{t("技术方案")}</SectionTitle>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
        {lists.map((list) => (
          <div
            className="flex flex-col items-center animation-card-120 cursor-pointer"
            onClick={() => {
              setCurrentInfo(list);
              setVisible(true);
            }}
          >
            <div className="w-80 flex-none rounded-lg overflow-hidden">
              <ContentfulImage
                className="h-full w-full object-contain"
                image={list?.coverImage!}
              />
            </div>
            <p className="mt-4">{list.name}</p>
          </div>
        ))}
      </div>
      <TechnicalSolutionInfoModal
        technicalSolutionInfo={currentInfo}
        onCancel={() => setVisible(false)}
        visible={visible}
      />
    </SkewMainContainer>
  );
};

export default TechnicalSolutionsView;
